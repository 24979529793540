import type { JobPost } from "~/types/jben/job_post";
import type { Board } from "~/types/jben/board";

export const createPostOpenGraphTags = (jobPost: JobPost) => {
  return [
    {
      property: "og:title",
      content: jobPost.title,
    },
    {
      property: "og:description",
      content: jobPost.job_post_location,
    },
    {
      property: "og:url",
      content: jobPost.public_url,
    },
  ];
};

export const createBoardOpenGraphTags = (board: Board, url: string) => {
  return [
    {
      property: "og:title",
      content: board.name,
    },
    {
      property: "og:description",
      content: board.content,
    },
    {
      property: "og:url",
      content: url,
    },
  ];
};
